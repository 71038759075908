import { FiHome } from "react-icons/fi"

const buttonSizes = {
  small: "w-24 h-8 text-sm",
  medium: "w-32 h-12 text-base",
  large: "w-40 h-12 text-lg",
}

export interface IButton {
  href: string
  label: string
  size?: "small" | "medium" | "large"
  iconName?: string
  textColor?: string
  bgColor?: string
  disabled?: boolean
  onClick?: () => void
}

export default function ALinkButton({
  href,
  label,
  size = "medium",
  iconName,
  textColor = "text-white",
  bgColor = "bg-blue-500",
  disabled = false,
}: IButton) {
  const sizeClasses = buttonSizes[size]
  const baseClasses = "flex items-center justify-center font-bold rounded focus:outline-none"

  const computedClasses = `${baseClasses} ${sizeClasses} ${textColor} ${bgColor} ${disabled ? "opacity-50 cursor-not-allowed" : "hover:bg-gray-550"}`

  return (
    <a className={computedClasses} href={href}>
      {iconName && <FiHome className="mr-2" />}
      {label}
    </a>
  )
}
