import { FaInstagram, FaYoutube } from "react-icons/fa"

const footerData = {
  companyName: "GENERAL COFFEE COORDINATOR",
  businessInfo: [
    "사업자 등록번호 : 105-12-38798",
    "호스팅 서비스 : 재너럴 커피 코디네이터",
    "06028 대한민국 서울특별시 강남구 압구정로4길 13-11 4층",
  ],
  copyright: "© 2020 General Coffee Coordinator All rights reserved",
  socialLinks: [
    {
      link: "https://www.instagram.com/gcc_works",
      icon: <FaInstagram />,
    },
    {
      link: "https://www.youtube.com/@user-ff7to7td8i",
      icon: <FaYoutube />,
    },
  ],
}

export default function Footer() {
  return (
    <footer className="bg-gray-900 text-white p-8">
      <div className="container max-w-[1148px] mx-auto grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <h1 className="text-base font-bold text-gray-250">{footerData.companyName}</h1>
          <div className="mt-4 text-sm text-gray-600">
            {footerData.businessInfo.map((info, index) => (
              <p key={index} className="mb-2">
                {info}
              </p>
            ))}
            <div className="mt-8 text-sm text-gray-600">{footerData.copyright}</div>
          </div>
        </div>
        <div className="flex flex-col justify-between">
          <div className="flex space-x-4 text-gray-400 text-2xl  lg:justify-end mt-4 lg:mt-0">
            {footerData.socialLinks.map(({ link, icon }, index) => (
              <a
                key={index}
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                className="hover:text-white"
              >
                {icon}
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}
