export default function LocationIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.0099 17.7395C16.638 17.7395 20.3899 13.9876 20.3899 9.3595C20.3899 4.73135 16.638 0.979492 12.0099 0.979492C7.38174 0.979492 3.62988 4.73135 3.62988 9.3595C3.62988 13.9876 7.38174 17.7395 12.0099 17.7395Z"
        fill="#FF7765"
      />
      <path
        d="M20.35 10C20.0769 12.6704 19.1787 15.2389 17.728 17.4975C16.2773 19.7561 14.315 21.6411 12 23V10H20.35Z"
        fill="#FF7765"
      />
      <path
        d="M3.66016 10C3.93213 12.6695 4.82886 15.2374 6.27777 17.4959C7.72668 19.7544 9.68705 21.64 12.0002 23V10H3.66016Z"
        fill="#FF7765"
      />
      <path
        d="M12.0098 12C13.6666 12 15.0098 10.6569 15.0098 9C15.0098 7.34315 13.6666 6 12.0098 6C10.3529 6 9.00977 7.34315 9.00977 9C9.00977 10.6569 10.3529 12 12.0098 12Z"
        fill="white"
      />
    </svg>
  )
}
