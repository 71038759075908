import { useMutation } from "../../../shared"

const baseUrl = "https://api.gccgroup.co.kr/"

interface PostInquiryRequest {
  email: string
  message: string
  serviceType: "KID_FOUNDATION" | "HONGI_GROUP" | "GENERAL_COFFEE_COORDINATOR"
  inquiryType: string
  name?: string
  additionalConsent?: boolean
  phone?: string
}

async function postInquiry(data: PostInquiryRequest) {
  const response = await fetch(baseUrl + "api/v1/general-inquiry", {
    method: "POST",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json",
    },
  })

  return response.json()
}

export default function usePostInquiry(props?: { onSuccess: () => void; onError: () => void }) {
  return useMutation<PostInquiryRequest, PostInquiryRequest>({
    mutationFn: postInquiry,
    onSuccess: props?.onSuccess,
    onError: props?.onError,
  })
}
