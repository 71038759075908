import clsx from "clsx"
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io"
import Slider, { Settings } from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { formatKoreanWon, useScrollTop } from "../../shared"

export default function LabPage() {
  useScrollTop()

  const labInfo = {
    section_1: {
      title: "커피작업실 창업교육",
      description: [
        "창업을 준비하시는 분들을 위한 커피교육이 진행됩니다.",
        "저희 커피교육은 소수인원으로만 구성하여 교육을 진행하고 있습니다.\n\n",
        "자격증을 위한 교육이 아닌, 매점을 운영하고 창업을 준비하시는 회원님들을 위한 교육이 진행됩니다.",
        "소수인원으로 진행하다보니 내가 모르는 부분, 어려운 부분을 하나하나 짚어가면서 알아갈수 있다는 장점이 있습니다.",
        "안정된 에스프레소 추출부터 장비관리 부분, 라떼 아트교육까지",
        "교육진행은 교육을 수강하시는 회원님들의 진도에 따라 조율하여 진행됩니다.",
      ],
    },
    section_2: {
      title: "교육과정",
      steps: [
        {
          image: "./images/mock_one.png",
          title: "1회",
          description: ["커피개론", "에스프레소 커피머신과 그라인더의 구조", "커피 추출과정 이해"],
        },
        {
          image: "./images/mock_two.png",
          title: "2회",
          description: [
            "커피머신과 그라인더 사용법",
            "에스프레소 추출 실습",
            "에스프레소의 3가지 맛 느껴보기",
          ],
        },
        {
          image: "./images/mock_three.png",
          title: "3회",
          description: [
            "원두 분쇄도에 따라 달라지는 추출과 맛의 변화",
            "에스프레소 물 추출하기 위한 분쇄도 조절 훈련",
            "일정한 에스프레소 추출하기",
          ],
        },
        {
          image: "./images/mock_one.png",
          title: "4회",
          description: [
            "우유스티밍 기초교육 라떼와 카푸치노 우유거품 비교 실습",
            "라떼아트 맛보기",
            "베리에이션 메뉴 알아보기",
          ],
        },
        {
          image: "./images/mock_two.png",
          title: "5회",
          description: ["기초 라떼아트 교육, 하트 및 로제타 만들기, 우유폼과 소스를 이용한 애칭"],
        },
        {
          image: "./images/mock_three.png",
          title: "6회",
          description: [
            "에스프레소 추출부터 한 잔의 완성된 메뉴, 커피 머신의 세척, 커피머신 관리 및 청소",
          ],
        },
      ],
    },
    section_3: {
      title: "교육비",
      description: [
        "신청가능인원 교육과정에 실습부분이 포함되어있어 1타임당 신청인원은 2명으로 제한됩니다.",
        "현금영수증/ 세금계산서발행/ 카드결제 모두 가능합니다.",
      ],
      costs: [
        { label: "교육 비용", amount: 880000 },
        { label: "재료 비용", amount: 110000 },
      ],
    },
    section_4: {
      title: "커피작업실 컨설팅",
      description: [
        "제너럴코디네이터에서는 바 컨설팅 도면지원, 포스터 및 배너 디자인 지원등의 다양한 서비스를 제공해 드리고 있습니다.\n\n",
        "바를 구성할 때, 전문가의 컨설팅 없이 진행하면 불편한 점과 어려운 점이 생길 수 있습니다. 이러한 부분은 전문가가 컨설팅해 주는 것이 더 확실하고 깔끔하게 진행됩니다.",
        "제너럴코디네이터의 컨설팅 서비스를 통해 제빙기, 냉장/냉동 쇼케이스, 매립형 넉박스, 피쳐린서 등 바에서 필요한 모든 것을 한 번에 진행할 수 있습니다. 여러 곳을 찾아다니며 머리 아프실 일 없이 편리하게 바를 구성하실 수 있습니다.",
      ],
    },
    section_5: {
      title: "찾아오시는 길",
      map: "./images/map.png",
      address: "서울시 강남구 압구정로 4길 13-11",
      addressDetail: "카페포엠 지하 1층 커피작업실",
    },
  }

  const sliderSettings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  const handleMapClick = () => {
    const naverMapUrl = `https://map.naver.com/v5/search/${encodeURIComponent(labInfo.section_5.address)}`
    window.open(naverMapUrl, "_blank")
  }

  return (
    <>
      <section>
        <div className="relative">
          <img
            src="./images/main.32eea178.png"
            className="w-full h-[300px] md:h-[400px] object-cover"
          />
          <div className="absolute inset-0 bg-gray-800 bg-opacity-50"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-white text-4xl md:text-5xl font-bold">LAB</span>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[100px] md:py-[150px]">
          <div className="flex flex-col lg:flex-row items-start gap-[60px]">
            <div className="max-w-[500px]">
              <h2 className="text-3xl md:text-4xl font-bold mb-5">{labInfo.section_1.title}</h2>
              <hr className="border-t-4 border-main-500 mb-5 w-1/5" />
              {labInfo.section_1.description.map((desc, index) => (
                <h2 key={index} className="text-base md:text-lg text-sub-a-700 whitespace-pre-line">
                  {desc}
                </h2>
              ))}
            </div>
            <div className="w-full h-[300px] md:h-[400px] desktop:h-[400px] desktop:w-full mt-5 md:mt-0">
              <img
                src="./images/education_banner.656c66c6.png"
                className="w-full h-full object-cover object-center"
                alt="Product Image"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-sub-a-075">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[100px] md:py-[150px]">
          <h2 className="text-center text-3xl md:text-4xl font-bold mb-12">
            {labInfo.section_2.title}
          </h2>
          <div className="px-6">
            <Slider {...sliderSettings}>
              {labInfo.section_2.steps.map((step, index) => (
                <div key={index} className="p-2 flex justify-center">
                  <div className="bg-white rounded-lg h-[450px] md:h-[480px] w-full flex flex-col">
                    <img
                      src={step.image}
                      alt={step.title}
                      className="w-full h-48 object-cover mb-6 rounded-t-lg"
                    />
                    <div className="px-6">
                      <h3 className="text-3xl md:text-4xl font-bold mb-5">{step.title}</h3>
                      <ul className="text-gray-600 text-base md:text-lg text-left flex-grow">
                        {step.description.map((desc, idx) => (
                          <li key={idx} className="mb-2">
                            {desc}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[100px] md:py-[150px]">
          <div className="flex flex-col md:flex-row justify-between">
            <div className={clsx("flex-1 mb-8", "md:mr-8 md:mb-0")}>
              <h2 className="text-3xl md:text-4xl font-bold mb-5">{labInfo.section_3.title}</h2>
              {labInfo.section_3.description.map((desc, index) => (
                <p key={index} className="text-base md:text-lg text-gray-700">
                  {desc}
                </p>
              ))}
            </div>

            <div className="flex flex-1 justify-center items-center flex-col gap-3 w-full">
              {labInfo.section_3.costs.map((cost, index) => (
                <ul
                  key={cost.label + index}
                  className="border border-gray-300 rounded-lg flex flex-row justify-around w-full md:w-[400px] h-[100px] items-center"
                >
                  <li className="text-black md:text-xl font-bold">{cost.label}</li>
                  <li className="text-black md:text-xl">{formatKoreanWon(cost.amount)}원</li>
                </ul>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-sub-a-075">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[100px] md:py-[150px]">
          <div className="flex flex-col lg:flex-row items-start gap-[60px]">
            <div className="w-full h-[300px] md:h-[400px] desktop:h-[400px] desktop:w-full mt-5 md:mt-0 order-2 lg:order-1">
              <img
                src="./images/consulting_banner.e68db45f.jpeg"
                className="w-full h-full object-cover object-center"
                alt="Product Image"
              />
            </div>
            <div className="max-w-[500px] order-1 lg:order-2">
              <h2 className="text-3xl md:text-4xl font-bold mb-5">{labInfo.section_4.title}</h2>
              <hr className="border-t-4 border-main-500 mb-5 w-1/5" />
              {labInfo.section_4.description.map((desc, index) => (
                <h2 key={index} className="text-base md:text-lg text-sub-a-700 whitespace-pre-line">
                  {desc}
                </h2>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[100px] md:py-[150px]">
          <h2 className="text-center text-3xl md:text-4xl font-bold mb-12">
            {labInfo.section_5.title}
          </h2>
          <div
            className="bg-white rounded-xl border-gray-300 border-[1px] cursor-pointer"
            onClick={handleMapClick}
          >
            <img src={labInfo.section_5.map} className="w-full bg-cover rounded-xl" />
            <p className="p-5">
              {labInfo.section_5.address} ({labInfo.section_5.addressDetail})
            </p>
          </div>
        </div>
      </section>
    </>
  )
}

function RightArrow(props: any) {
  const { className, style, onClick } = props

  if (className.includes("slick-disabled")) {
    return null
  }

  return (
    <IoIosArrowDroprightCircle
      className={`${className} opacity-50 hover:opacity-100 right-[-25px] translate-x-0 w-11 h-11 fill-black z-[1]`}
      style={style}
      onClick={onClick}
    />
  )
}

function LeftArrow(props: any) {
  const { className, style, onClick } = props

  if (className.includes("slick-disabled")) {
    return null
  }

  return (
    <IoIosArrowDropleftCircle
      className={`${className} opacity-50 hover:opacity-100 left-[-25px] translate-x-0 w-11 h-11 fill-black z-[1]`}
      style={style}
      onClick={onClick}
    />
  )
}
