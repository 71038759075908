import { createBrowserRouter } from "react-router-dom"
import { AboutPage, HomePage, LabPage, LeaseRentalPage, SupportPage } from "../pages"
import ProductDetail from "../pages/product-detail/page"
import ProductPage from "../pages/product/page"
import Layout from "./layout"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: <AboutPage />,
      },
      {
        path: "/lab",
        element: <LabPage />,
      },
      {
        path: "/lease",
        element: <LeaseRentalPage />,
      },
      {
        path: "/product",
        element: <ProductPage />,
      },
      {
        path: "/product/:id",
        element: <ProductDetail />,
      },
      {
        path: "/support",
        element: <SupportPage />,
      },
    ],
  },
])
