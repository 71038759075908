import { useLayoutEffect } from "react"

export default function useScrollTop() {
  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200)

    return () => {
      clearTimeout(timeout)
    }
  }, [])
}
