/* eslint-disable no-unused-vars */
import { useCallback, useState } from "react"

interface IUseMutation<TVariables, TData> {
  mutationFn: (data: TVariables) => Promise<TData>
  onSuccess?: () => void
  onError?: () => void
}

export default function useMutation<TVariables extends object, TData = unknown>({
  mutationFn,
  onError,
  onSuccess,
}: IUseMutation<TVariables, TData>) {
  const [data, setData] = useState<TData>()
  const [isLoading, setLoading] = useState(false)

  const mutate = useCallback(
    (variables: TVariables) => {
      setLoading(true)
      mutationFn(variables)
        .then((data) => {
          setData(data)
          onSuccess?.()
        })
        .catch(() => {
          onError?.()
        })
        .finally(() => {
          setLoading(false)
        })
    },
    [onSuccess, onError, mutationFn]
  )

  const mutateAsync = useCallback(
    async (variables: TVariables) => {
      setLoading(true)
      try {
        const response = await mutationFn(variables)
        onSuccess?.()
        setData(response)
        return response
      } catch (error) {
        onError?.()
      } finally {
        setLoading(false)
      }
    },
    [onSuccess, onError, mutationFn]
  )

  return {
    isLoading,
    data,
    mutate,
    mutateAsync,
  }
}
