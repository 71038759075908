import { Outlet } from "react-router-dom"
import { MainFooter, MainHeader } from "../widgets"

export default function Layout() {
  return (
    <>
      <MainHeader />
      <main className="overflow-hidden">
        <Outlet />
      </main>
      <MainFooter />
    </>
  )
}
