//이미지
// 제품명
export const productdata = [
  {
    id: 1,
    image: "/images/product/aremde.jpg",
    title: "Aremde",
    type: "Brand",
    to: "/product/1",
  },
  {
    id: 2,
    image: "/images/product/spirit2.png",
    title: "Kees van der Westen",
    type: "Brand",
    to: "/product/2",
  },
  {
    id: 3,
    image: "/images/product/coffeedoor.jpg",
    title: "Coffee Door",
    type: "Brand",
    to: "/product/3",
  },

  // coffeecaptain

  {
    id: 5,
    image: "/images/product/coffeecaptain.jpg",
    title: "Coffee Captain",
    type: "Product",
    to: "/product/5",
  },
  //coffeejavise
  {
    id: 6,
    image: "/images/product/coffeejarvis.jpg",
    title: "Coffee Jarvise",
    type: "Product",
    to: "/product/6",
  },
  //coffeemari
  {
    id: 7,
    image: "/images/product/coffeemari.jpg",
    title: "Coffee Mari",
    type: "Product",
    to: "/product/7",
  },

  // coffeechocod
  {
    id: 9,
    image: "/images/product/coffeechocod.jpg",
    title: "Coffee Chocod",
    type: "Product",
    to: "/product/9",
  },

  //coffeebuza
  {
    id: 8,
    image: "/images/product/coffeebuza.jpg",
    title: "Coffee Buza",
    type: "Product",
    to: "/product/8",
  },
  // coffeemacho
  {
    id: 4,
    image: "/images/product/coffeemacho.jpg",
    title: "Coffee Macho",
    type: "Product",
    to: "/product/4",
  },
]
