// 제품명
export const aboutImages = [
  { src: "/images/about/about2.jpg", alt: "Coffee!2" },
  { src: "/images/about/about4.png", alt: "Coffee!4" },
  {
    src: "/images/about/about9.jpg",
    alt: "Coffee!1",
  },
  { src: "/images/about/about10.jpg", alt: "Coffee!10" },
  { src: "/images/about/about11.jpg", alt: "Coffee!11" },
]

export const timelineData = [
  { year: "2004", description: "바리스타 커뮤니티 ‘커피작업실’ 운영" },
  {
    year: "2006",
    description:
      "마리글라라 브랜드 출시 국내로스팅 <마리글라라> 브랜드 출시. 네덜란드 수제머신 <MIRAGE> 한국 독점 수입",
  },
  { year: "2007", description: "The Ultimate Baristar Challenge Korea" },
  {
    year: "2008",
    description:
      "국내 로스팅 <NOVE 9> 브랜드 출시. 로스팅 확장. D.I. D Srl - Orchestrale. First Collection <Einica> 독점 수입",
  },
  { year: "2009", description: "커피작업실 직영 매장 오픈. 리파드 티 브랜드 독점 수입" },
  {
    year: "2010",
    description:
      "프렌차이즈 카페 <CAFE4M> 설립 운영. 국내로스팅 <NOVE9-2> 브랜드 출시. 제품디자인 전문회사 <4M DESIGN> 설립",
  },
  {
    year: "2011",
    description: "<CAFE4M> 직영 2호점 논현동 The Agit 오픈 Musubo 런칭. Loveramics 런칭",
  },
  { year: "2012", description: "로스팅 공장 확장. Takeya 런칭" },
  {
    year: "2013",
    description:
      "네덜란드 수제머신 <Spirit> 한국 독점 수입. CAFE4M 가로수길 The Base 매장 오픈. 로스팅 공장 확장 이전. Toffin 런칭",
  },
  { year: "2014", description: "CAFE4M 갤러리 정식 오픈. 커피작업실 랩실 개설" },
  {
    year: "2015",
    description:
      "CAFE RICHARD 허브 라인 독점 수입. 커피작업실 세미나 프로그램. 커피작업실 쇼핑몰 런칭. 국내 로스팅 7종 브랜드 출시",
  },
  { year: "2016", description: "용인 물류센터 확장. 카페 쇼핑몰 <Coffeecodi.com> 사이트 오픈" },
  { year: "2017", description: "<LEGASEE> 빈티지 가구 브랜드 런칭. Coffee Reantal 사업 확장" },
  { year: "2019", description: "<AREMDE> 넥서스원 한국 공식 수입 런칭" },
]
