import { productdata } from "../../data/productdata"
import { useScrollTop } from "../../shared"

export default function ProductPage() {
  useScrollTop()

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 p-3">
      {productdata.map((product) => (
        <div className="relative overflow-hidden group cursor-pointer h-80" key={product.id}>
          <img
            src={product.image}
            alt={product.title}
            className="w-full h-full object-cover transform transition-transform duration-500 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-gray-800 bg-opacity-100 opacity-50 group-hover:opacity-10 transition-opacity duration-500"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-white text-2xl font-bold">{product.title}</span>
          </div>
        </div>
      ))}
    </div>
  )
}
