export default function EmailIcon() {
  return (
    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.5 7V18C2.49936 18.5136 2.69631 19.0076 3.05002 19.3799C3.40373 19.7523 3.88711 19.9743 4.40003 20H19.6C20.1129 19.9743 20.5963 19.7523 20.95 19.3799C21.3037 19.0076 21.5006 18.5136 21.5 18V7H2.5Z"
        fill="#FFE6E2"
      />
      <path
        d="M12 14.5L21.5 7.5V7C21.5006 6.48645 21.3037 5.99232 20.95 5.62C20.5963 5.24767 20.1129 5.02568 19.6 5H4.40003C3.88711 5.02568 3.40373 5.24767 3.05002 5.62C2.69631 5.99232 2.49936 6.48645 2.5 7V7.5L12 14.5Z"
        fill="#FF7765"
      />
    </svg>
  )
}
