import { useEffect, useRef, useState } from "react"
import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io"
import Slider from "react-slick"
import { aboutImages, timelineData } from "../../data/aboutimages"

import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { useScrollTop } from "../../shared"

export default function AboutPage() {
  useScrollTop()

  const sliderRef = useRef<Slider>(null)
  const [imageIndex, setImageIndex] = useState(0)

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    afterChange: (index: number) => {
      setImageIndex(index)
    },
  }

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("show")
          }
        })
      },
      {
        threshold: 0.1,
      }
    )

    document.querySelectorAll(".fade-in-right").forEach((el) => {
      observer.observe(el)
    })

    return () => observer.disconnect()
  }, [])

  return (
    <>
      {/* Section 1 */}
      <section className="bg-sub-a-075">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[50px] md:py-[100px] text-center">
          <img
            src="/images/logo.png"
            alt="GCC Logo"
            className="mx-auto mb-8 w-[170px] object-contain"
          />
          <h2 className="text-3xl font-bold mb-4">GENERAL COFFEE COORDINATOR</h2>
          <p className="text-xl font-semibold mb-4 text-main-500">
            GCC는 오프라인과 온라인 모든 곳에서 한국의 커피 산업을 선도합니다.
          </p>
          <p className="text-lg mb-12">
            GCC는 2004년 설립된 국내 최대 커뮤니티 커피작업실을 바탕으로 세계 각국의 대표 커피 장비
            브랜드의 유통 및 교육등 최고의 커피 산업 서비스를 제공합니다.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2">
            <div className="text-center border-y py-8">
              <h3 className="text-xl font-semibold mb-2 text-main-500">CAFE4M Franchise</h3>
              <p className="text-lg">
                서울특별시 강남구 압구정로4길 13-11
                <br />
                <span className="font-semibold text-black">TEL 02)512-4440</span>
                <br />4 호점 (압구정점, 북촌점, 가로수길점, 잠실점)
              </p>
            </div>

            <div className="text-center border-y py-8 border-gray-300 md:border-l">
              <h3 className="text-xl font-semibold mb-2 text-main-500">커피작업실 Naver Cafe</h3>
              <p className="text-lg">
                총 회원수 24만명
                <br />
                <a href="https://cafe.naver.com/barman" target="_blank" rel="noopener noreferrer">
                  <span className="font-semibold text-black">https://cafe.naver.com/barman</span>
                </a>{" "}
                <br />
                50개 이상의 제휴파트너 보유
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 */}
      <section>
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[50px] md:py-[100px] text-center">
          <div className="text-start">
            <h2 className="text-3xl md:text-4xl font-bold mb-12">GENERAL COFFEE COORDINATOR</h2>
            <hr className="border-t-4 border-main-500 mb-5 w-1/5" />
            <p className="text-lg mb-2">서울특별시 강남구 압구정로4길 13-11</p>
            <p className="text-lg mb-8">{"TEL 02)512-4440"}</p>
          </div>
          <div className="relative">
            <Slider ref={sliderRef} {...settings}>
              {aboutImages.map((image, index) => (
                <div key={index}>
                  <img
                    src={image.src}
                    alt={image.alt}
                    className="w-full aspect-video object-cover"
                  />
                </div>
              ))}
            </Slider>

            <div className="hidden desktop:flex gap-1 justify-center overflow-hidden ">
              {aboutImages.map((image, index) => (
                <img
                  onClick={() => sliderRef.current?.slickGoTo(index)}
                  key={index}
                  src={image.src}
                  alt={`thumbnail_${index}`}
                  className="thumb-image w-1/5 aspect-square object-cover cursor-pointer border-2 opacity-50 transition-all duration-500"
                  style={{ opacity: index === imageIndex ? "0.5" : "1" }}
                />
              ))}
            </div>
          </div>
        </div>
      </section>

      {/* Section 3 */}
      <section className="bg-sub-a-075">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[50px] md:py-[100px]">
          <div className="flex flex-col md:flex-row">
            <div className="md:border-r md:border-gray-300 pr-0 md:pr-8 flex-1">
              <h3 className="text-main-500 text-lg mb-2">GCC 세부 연혁</h3>
              <h2 className="text-3xl font-bold mb-4">
                HISTORY OF
                <br />
                GENERAL COFFEE COORDINATOR
              </h2>
              <hr className="border-t-4 border-main-500 mb-5 w-1/5" />
              <p className="text-xl font-bold mb-4">GCC는 계속 성장 중 입니다.</p>
              <p className="text-gray-600 mb-4 text-base md:text-lg md:mb-20 whitespace-pre-line">
                {"GCC는 전문 바리스타 교육과 바리스타 커뮤니티 운영을 시작으로 제너럴 커피" +
                  "코디네이터를 설립과 함께 로스팅 공장을 확장, 국내로스팅 프리미엄 라인 브랜드 4종," +
                  "보급형 라인 브랜드 7종을 출시하여 새로운 제품에 대한 끝없는 개발과 연구를 하고 있습니다.\n\n" +
                  "해외 무역 사업부에서는 네덜란드 수제 명품 머신 KEES VAN DER WESTEN과" +
                  "하이엔드 커피와 티,커피웨어 등을 공식 수입하고 있습니다. 교육사업부에서 커피문화를" +
                  "만들어가는 프렌차이즈 카페 ‘CAFÉ 4M’을 운영하고 있습니다."}
              </p>
            </div>

            <div className="flex flex-col flex-[2]">
              {timelineData.map((item, index) => (
                <div key={index} className="fade-in-right md:ml-8">
                  <h4 className="text-lg md:text-xl font-bold mb-2">{item.year}</h4>
                  <ul className="text-base md:text-lg mb-8">
                    <p>{item.description}</p>
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

function RightArrow(props: any) {
  const { className, style, onClick } = props

  if (className.includes("slick-disabled")) {
    return null
  }

  return (
    <IoIosArrowDroprightCircle
      className={`${className} opacity-80 hover:opacity-100 right-0 translate-x-0 w-11 h-11 fill-white z-[1]`}
      style={style}
      onClick={onClick}
    />
  )
}

function LeftArrow(props: any) {
  const { className, style, onClick } = props

  if (className.includes("slick-disabled")) {
    return null
  }

  return (
    <IoIosArrowDropleftCircle
      className={`${className} opacity-80 hover:opacity-100 left-0 translate-x-0 w-11 h-11 fill-white z-[1]`}
      style={style}
      onClick={onClick}
    />
  )
}
