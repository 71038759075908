import { FiHome } from "react-icons/fi"

const buttonSizes = {
  small: "w-24 h-8 text-sm",
  medium: "w-32 h-10 text-base",
  large: "w-40 h-12 text-lg",
}

export interface IButton {
  label: string
  type?: "submit" | "reset" | "button" | undefined
  size?: "small" | "medium" | "large"
  iconName?: string
  textColor?: string
  bgColor?: string
  disabled?: boolean
  isLoading?: boolean
  onClick?: () => void
}

export default function Button({
  label,
  iconName,
  type,
  size = "medium",
  textColor = "text-white",
  bgColor = "bg-blue-500",
  disabled = false,
  isLoading = false,
  onClick,
}: IButton) {
  const sizeClasses = buttonSizes[size]
  const baseClasses = "flex items-center justify-center font-bold rounded focus:outline-none"

  const computedClasses = `${baseClasses} ${sizeClasses} ${textColor} ${bgColor} ${disabled ? "cursor-not-allowed" : "hover:bg-opacity-80"}`

  return (
    <button type={type} className={computedClasses} onClick={onClick} disabled={disabled}>
      {isLoading ? (
        <div className="flex gap-x-2 justify-center items-center">
          <span className="sr-only">Loading...</span>
          <div className="h-3 w-3 bg-white rounded-full animate-bounce [animation-delay:-0.3s]"></div>
          <div className="h-3 w-3 bg-white rounded-full animate-bounce [animation-delay:-0.15s]"></div>
          <div className="h-3 w-3 bg-white rounded-full animate-bounce"></div>
        </div>
      ) : (
        <>
          {iconName && <FiHome className="mr-2" />}
          {label}
        </>
      )}
    </button>
  )
}
