import clsx from "clsx"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import { FiMenu } from "react-icons/fi"
import { Link } from "react-router-dom"

const headerInfo = {
  title: "제너럴커피코디네이터",
  links: [
    { to: "/", label: "홈" },
    { to: "/about", label: "회사소개" },
    { to: "/product", label: "제품안내" },
    { to: "/lease", label: "리스 / 렌탈" },
    { to: "/lab", label: "LAB" },
    { to: "/support", label: "고객지원" },
  ],
}

export default function MainHeader() {
  const [menuOpen, setMenuOpen] = useState(false)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }

    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  const mobileMenu = () => {
    return (
      <nav className={clsx("flex flex-row justify-between items-center p-5", "desktop:hidden")}>
        <div className={"flex items-center"}>
          <Link to={"/"}>
            <img src="/images/logo.png" alt="GCC Logo" className="w-[140px] object-contain" />
          </Link>
        </div>
        <button className="text-2xl" onClick={() => setMenuOpen(!menuOpen)}>
          <FiMenu />
        </button>
      </nav>
    )
  }

  return (
    <header
      className={`bg-white sticky top-0 z-10 ${isScrolled ? "border-b border-b-gray-300" : ""}`}
    >
      {mobileMenu()}
      <DesktopMenu />
      <motion.ul
        initial={{ height: 0 }}
        animate={{ height: menuOpen ? "auto" : 0 }}
        transition={{ duration: 0.3 }}
        className="bg-white overflow-hidden w-full flex flex-col absolute"
      >
        {headerInfo.links.map((link) => (
          <li key={link.to}>
            <Link to={link.to} onClick={() => setMenuOpen(false)}>
              <p className="text-[15px] text-gray-700 py-[18px] px-[20px] hover:bg-gray-100 cursor-pointer">
                {link.label}
              </p>
            </Link>
          </li>
        ))}
      </motion.ul>
    </header>
  )
}

function DesktopMenu() {
  return (
    <nav className="hidden desktop:flex justify-center items-center p-5 w-full h-[80px]">
      <div className="container max-w-[1148px] mx-auto flex justify-between items-center">
        <Link to={"/"}>
          <img src="/images/logo.png" alt="GCC Logo" className="w-[140px] object-contain" />
        </Link>
        <div className="flex space-x-8">
          {headerInfo.links.map((link) => (
            <Link key={link.to} to={link.to} className="hover:text-main-500 text-gray-700 ">
              {link.label}
            </Link>
          ))}
        </div>
        <div className="w-48" />
      </div>
    </nav>
  )
}
