import React from "react"
import Slider, { Settings } from "react-slick"
import { InfiniteCarouselProps } from "./infinite-carousel.type"

import "./infinite-carousel.css"

export default function InfiniteCarousel<TData = unknown>({
  images,
  keyExtractor,
  renderImage,
}: InfiniteCarouselProps<TData>) {
  const settings: Settings = {
    dots: false,
    infinite: true,
    speed: 7000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  }

  return (
    <Slider {...settings}>
      {images.map((img, index) => (
        <React.Fragment key={keyExtractor?.(img, index) ?? index}>
          {renderImage({ item: img, index })}
        </React.Fragment>
      ))}
    </Slider>
  )
}
