import { homgProduct, instagramImage, services } from "../../data/homebrandlistimage"
import { InfiniteCarousel } from "../../features"
import { ALinkButton, useScrollTop } from "../../shared"

export default function HomePage() {
  useScrollTop()

  return (
    <>
      {/* Section 1 */}
      <section>
        <div className="relative h-[calc(100vh-71px)]">
          <img src="./images/02.png" className="w-full h-full object-cover" />

          <div className="text-center text-white absolute top-0 w-full h-full flex flex-col justify-center px-6">
            <h1 className="text-3xl md:text-5xl font-bold">GENERAL COFFEE COORDINATOR</h1>
            <h3 className="text-base md:text-xl mt-4">
              GCC는 커피산업의 A부터 Z까지 하이엔드 서비스를 제공합니다.
            </h3>

            <div className="mt-8">
              <a href="#section2">
                <span className="px-6 py-3 border border-white rounded-full hover:bg-main-500 hover:border-transparent transition duration-300">
                  About GCC
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Section 2 */}
      <section id="section2" className="bg-sub-a-075">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[100px] md:py-[150px] text-center">
          <div className="max-w-7xl mx-auto">
            <p className="text-lg mb-4 text-main-500 font-bold">
              2004년 ‘커피작업실’을 시작으로 현재 세계적인 수준의 바리스타 교육과 카페 컨설팅을
              제공합니다.
            </p>

            <p className="text-gray-600 mb-4 text-base md:text-lg md:mb-20">
              GCC는 지난 20여 년 동안 전문 바리스타 교육과 커뮤니티로 시작해, 제너럴 커피
              코디네이터를 설립하였습니다. 우리는 해외 하이엔드 커피머신의 수입 및 유통, 로스팅 공장
              확장을 통해 커피 산업의 A부터 Z까지 컨설팅을 제공합니다. 또한, 프리미엄과 보급형
              로스팅 브랜드를 출시하며, 끊임없는 제품 개발과 연구에 힘쓰고 있습니다.
            </p>
            <div className="grid md:grid-cols-4 gap-6">
              {homgProduct.map((logo, index) => (
                <div
                  className="relative group border justify-center items-center md:block hidden px-4"
                  key={index}
                >
                  <img
                    src={logo.logo}
                    className="mx-auto h-20 transition duration-300 object-contain"
                  />
                  <div className="absolute inset-0 bg-gray-500 opacity-0 group-hover:opacity-50 transition duration-300"></div>
                </div>
              ))}
            </div>

            <div className="mt-3 md:mt-12">
              <a
                href="/product"
                className="inline-block px-6 py-3 border border-gray-600 rounded-full hover:text-white hover:bg-main-500 hover:border-transparent transition duration-300 bg-white"
              >
                모든 제품 보러가기
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* Section 3 */}
      <section>
        <video
          className="w-full h-full"
          src="/video/video.mp4"
          title="YouTube video player"
          controls
          autoPlay
          muted // Autoplay in most browsers requires the video to be muted
          loop // Optional: if you want the video to loop
          playsInline // Helps with autoplay on iOS devices
        >
          Your browser does not support the video tag.
        </video>
      </section>

      {/* Section 4 */}
      <section className="bg-sub-a-075 py-[100px] md:py-[150px]">
        <div className="container max-w-[calc(100%-48px)] desktop:max-w-[1148px] mx-auto text-center">
          <div className="mb-8">
            <h2 className="text-3xl md:text-4xl font-bold mb-5">고객지원 & 맞춤형 컨설팅</h2>
            <p className="text-base md:text-lg text-gray-600 whitespace-pre-line">
              {
                "제너럴커피코디네이터는 언제나 커피 산업과 고객 발전을 최우선 가치로 소비자분들의 모든 상담에 성실히 임하겠습니다."
              }
            </p>
          </div>
          <div className="flex flex-col desktop:flex-row justify-between items-start space-y-3 desktop:space-y-0 desktop:space-x-8 ">
            {services.map((service, index) => (
              <div
                key={index}
                className="flex flex-col items-center text-center p-6 border-gray-200 rounded-lg w-full desktop:w-1/3 desktop:h-[330px]"
              >
                {service.icon}
                <h2 className="text-xl md:text-3xl font-semibold mb-4">{service.title}</h2>
                <div className="text-gray-600 mb-4 text-base md:text-lg">
                  <p key={index} className="max-desktop:whitespace-pre-line">
                    {service.description}
                  </p>
                </div>
                <div className="mt-auto">
                  <ALinkButton
                    label={service.link.text}
                    href={service.link.to ?? service.link.href ?? ""}
                    bgColor="bg-gray-075"
                    textColor="text-black"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Section 5 */}
      <section className="bg-white py-[50px] md:py-[100px]">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto text-center mb-10">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">INSTAGRAM</h2>
          <p className="text-base md:text-lg text-gray-600">
            제너럴커피코디네이터의 인스타그램을 통해 최신 소식을 확인 하실 수 있습니다.
          </p>
        </div>
        <div className="overflow-hidden relative">
          <InfiniteCarousel
            images={instagramImage}
            keyExtractor={(item) => item.src}
            renderImage={({ item, index }) => {
              return (
                <a
                  href={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  key={index}
                  className="instagram-image mx-2"
                >
                  <img
                    src={item.src}
                    alt={`Instagram Post ${index + 1}`}
                    className="object-cover w-full aspect-square hover:scale-105 hover:shadow-lg"
                  />
                </a>
              )
            }}
          />
        </div>
      </section>
    </>
  )
}
