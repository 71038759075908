import { IoIosArrowDropleftCircle, IoIosArrowDroprightCircle } from "react-icons/io"
import Slider, { Settings } from "react-slick"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"
import { useScrollTop } from "../../shared"

export default function LeaseRentalPage() {
  useScrollTop()

  const labInfo = {
    section_1: {
      title: "커피머신  리스 / 렌탈",
      description: [
        "창업을 준비하시는 분들을 위한 커피 머신 리스 및 렌탈 서비스가 제공됩니다.",
        "저희 서비스는 본사 방문 또는 유선 상담을 통해 진행됩니다.\n\n",
        "고객의 니즈에 맞는 커피 머신과 원두를 선택하고, 심사 서류를 제출하여 신청을 완료합니다.",
        "심사 완료 후, 계약서를 작성하고 머신을 지정된 주소로 배송합니다.",
        "구매자에게 머신을 인도하고, 전문 엔지니어가 설치를 지원합니다.",
      ],
    },
    section_2: {
      title: "리스 / 렌탈 과정",
      steps: [
        {
          image: "./images/product/aremde2.jpg",
          title: "1번 방문 ",
          subtitle: "방문 및 유선상담",
          description: ["본사방문 또는 유선을 통한 전문가와 상담진행"],
        },
        {
          image: "./images/mock_two.png",
          title: "2번",
          subtitle: "렌탈 물품 확정 / 신청 및 심사",
          description: [
            "렌탈: 고객의 니즈에 맞는 커피머신 및 원두 선택",
            "리스: 심사 필요서류 제출 및 심사신청",
          ],
        },
        {
          image: "./images/mock_three.png",
          title: "3번",
          subtitle: "계약서 작성",
          description: [
            "렌탈: 렌탈 물품 확정 후 계약서 작성",
            "리스: 승인 후 계약서 및 초기 금액 납입",
          ],
        },
        {
          image: "./images/mock_one.png",
          title: "4번",
          subtitle: "계약 확정",
          description: ["계약 확정 후 머신 출고지정 된 주소로 배송"],
        },
        {
          image: "./images/mock_two.png",
          title: "5번",
          subtitle: "머신 인도 및 설치",
          description: ["구매자에게 머신 인도 및 전문 엔지니어 설치 지원"],
        },
      ],
    },
    section_3: {
      title: "교육비",
      description: [
        "신청가능인원 교육과정에 실습부분이 포함되어있어 1타임당 신청인원은 2명으로 제한됩니다.",
        "현금영수증/ 세금계산서발행/ 카드결제 모두 가능합니다.",
      ],
      costs: [
        { label: "교육 비용", amount: 550000 },
        { label: "재료 비용", amount: 110000 },
      ],
    },
    section_4: {
      title: "커피작업실 컨설팅",
      description: [
        "제너럴코디네이터에서는 바 컨설팅 도면지원, 포스터 및 배너 디자인 지원등의 다양한 서비스를 제공해 드리고 있습니다.\n\n",
        "바를 구성할 때, 전문가의 컨설팅 없이 진행하면 불편한 점과 어려운 점이 생길 수 있습니다. 이러한 부분은 전문가가 컨설팅해 주는 것이 더 확실하고 깔끔하게 진행됩니다.",
        "제너럴코디네이터의 컨설팅 서비스를 통해 제빙기, 냉장/냉동 쇼케이스, 매립형 넉박스, 피쳐린서 등 바에서 필요한 모든 것을 한 번에 진행할 수 있습니다. 여러 곳을 찾아다니며 머리 아프실 일 없이 편리하게 바를 구성하실 수 있습니다.",
      ],
    },
    section_5: {
      title: "찾아오시는 길",
      map: "./images/map.png",
      address: "서울시 강남구 압구정로 4길 13-11",
      addressDetail: "카페포엠 지하 1층 커피작업실",
    },
  }

  const sliderSettings: Settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    prevArrow: <LeftArrow />,
    nextArrow: <RightArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <>
      <section>
        <div className="relative">
          <img
            src="./images/product/aremde2.jpg"
            className="w-full h-[300px] md:h-[400px] object-cover"
          />
          <div className="absolute inset-0 bg-gray-800 bg-opacity-50"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <span className="text-white text-4xl md:text-5xl font-bold">Lease & Rental</span>
          </div>
        </div>
      </section>

      <section className="bg-white">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[100px] md:py-[150px]">
          <div className="flex flex-col lg:flex-row items-start gap-[60px]">
            <div className="max-w-[500px]">
              <h2 className="text-3xl md:text-4xl font-bold mb-5">{labInfo.section_1.title}</h2>
              <hr className="border-t-4 border-main-500 mb-5 w-1/5" />
              {labInfo.section_1.description.map((desc, index) => (
                <h2 key={index} className="text-base md:text-lg text-sub-a-700 whitespace-pre-line">
                  {desc}
                </h2>
              ))}
            </div>
            <div className="w-full h-[300px] md:h-[400px] desktop:h-[400px] desktop:w-full mt-5 md:mt-0">
              <img
                src="./images/product/aremde.jpg"
                className="w-full h-full object-cover object-center"
                alt="Product Image"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="bg-sub-a-075">
        <div className="container max-w-[calc(100%-40px)] desktop:max-w-[1148px] mx-auto py-[100px] md:py-[150px]">
          <h2 className="text-center text-3xl md:text-4xl font-bold mb-12">
            {labInfo.section_2.title}
          </h2>
          <div className="px-6">
            <Slider {...sliderSettings}>
              {labInfo.section_2.steps.map((step, index) => (
                <div key={index} className="p-2 flex justify-center">
                  <div className="bg-white rounded-lg h-[480px] w-full flex flex-col">
                    <img
                      src={step.image}
                      alt={step.title}
                      className="w-full h-48 object-cover mb-6 rounded-t-lg"
                    />
                    <div className="px-6">
                      <h3 className="text-3xl md:text-4xl font-bold mb-5">{step.title}</h3>
                      <h4 className="text-lg md:text-xl font-bold text-sub-a-700 mb-5">
                        {step.subtitle}
                      </h4>
                      <ul className="text-gray-600 text-base md:text-lg text-left flex-grow">
                        {step.description.map((desc, idx) => (
                          <li key={idx} className="mb-2">
                            {desc}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
            <div className="mt-3 flex justify-end ">
              <a
                href="http://pf.kakao.com/_kxexceM"
                target="_blank"
                rel="noopener noreferrer"
                className="inline-block px-6 py-3 border border-gray-600 rounded-full hover:text-white hover:bg-main-500 hover:border-transparent transition duration-300 bg-white"
              >
                문의하기
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

function RightArrow(props: any) {
  const { className, style, onClick } = props

  if (className.includes("slick-disabled")) {
    return null
  }

  return (
    <IoIosArrowDroprightCircle
      className={`${className} opacity-50 hover:opacity-100 right-[-25px] translate-x-0 w-11 h-11 fill-black z-[1]`}
      style={style}
      onClick={onClick}
    />
  )
}

function LeftArrow(props: any) {
  const { className, style, onClick } = props

  if (className.includes("slick-disabled")) {
    return null
  }

  return (
    <IoIosArrowDropleftCircle
      className={`${className} opacity-50 hover:opacity-100 left-[-25px] translate-x-0 w-11 h-11 fill-black z-[1]`}
      style={style}
      onClick={onClick}
    />
  )
}
