//이미지

import { IoHomeOutline } from "react-icons/io5"
import { LiaPhoneAltSolid } from "react-icons/lia"
import { PiNotePencilDuotone } from "react-icons/pi"

// 제품명
export const homgProduct = [
  {
    logo: "/images/home/kees.png",
    title: "Kees van der Westen",
  },
  {
    logo: "/images/home/arem.png",
    title: "AREMDE",
  },
  {
    logo: "/images/home/keojakcafe.png",
    title: "Cafe Richard",
  },
  {
    logo: "/images/cafe4m.png",
    title: "Cafe4M",
  },
]

export const services = [
  {
    icon: <PiNotePencilDuotone className="w-10 md:w-16 h-16 text-main-600 mb-4" />,
    title: "리스 프로그램 및 컨설팅",
    description: "예산, 비지니스 환경, 타겟층에 맞는 다양한 컨설팅 서비스를 제공해드립니다.",
    link: {
      to: "/support",
      text: "컨설팅문의",
    },
  },
  {
    icon: <IoHomeOutline className="w-10 md:w-16 h-16 text-main-600 mb-4" />,
    title: "온라인 쇼핑몰",
    description: "제너럴커피코디네이터의 제품은 온라인몰을 통해 구매가 가능합니다.",
    link: {
      href: "https://coffeecodi.com/",
      text: "온라인샵",
    },
  },
  {
    icon: <LiaPhoneAltSolid className="w-10 md:w-16 h-16 text-main-600 mb-4" />,
    title: "고객지원",
    description: (
      <>
        제너럴커피코디네이터 제품 관련 문의는 아래로 연락바랍니다.
        <br />
        <strong>SEOUL:</strong>{" "}
        <a href="tel:02-512-6024" className="text-gray-800">
          02-512-6024
        </a>
      </>
    ),
    link: {
      href: "http://pf.kakao.com/_kxexceM",
      text: "KAKAOTALK",
    },
  },
]

export const instagramImage = [
  {
    src: "/images/insta/insta1.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta2.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta3.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta4.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta5.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta6.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta7.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta8.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta9.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta10.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta11.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta1.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta2.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta3.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta4.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta5.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta6.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta7.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta8.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta9.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta10.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta11.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta1.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta2.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta3.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta4.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta5.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta6.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta7.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta8.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta9.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta10.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta11.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta1.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta2.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta3.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta4.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta5.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta6.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta7.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta8.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta9.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta10.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
  {
    src: "/images/insta/insta11.jpeg",
    link: "https://www.instagram.com/cafe4m",
  },
]
