export default function PhoneIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.4693 7.86983L8.60932 9.73982C9.36982 10.8431 10.2301 11.8741 11.1793 12.8198C12.1285 13.7692 13.1629 14.6295 14.2693 15.3898L16.1293 13.5298C16.3167 13.3436 16.5701 13.239 16.8343 13.239C17.0985 13.239 17.352 13.3436 17.5393 13.5298L21.0793 17.0698C21.2656 17.2572 21.3701 17.5106 21.3701 17.7748C21.3701 18.039 21.2656 18.2925 21.0793 18.4798L20.3193 19.2398L18.7693 20.7898C18.4929 21.0648 18.142 21.2529 17.7601 21.3311C17.3781 21.4092 16.9816 21.374 16.6193 21.2298C15.7659 20.8657 14.9315 20.4585 14.1193 20.0098C12.004 18.8298 10.0622 17.3625 8.34932 15.6498C6.63507 13.9383 5.16763 11.9963 3.98932 9.87982C3.53872 9.06865 3.13149 8.23415 2.76933 7.37982C2.62636 7.01663 2.59278 6.61955 2.67274 6.23752C2.75271 5.85548 2.9427 5.50519 3.21932 5.22983L4.75932 3.68982L5.51933 2.91982C5.61229 2.82609 5.72288 2.7517 5.84474 2.70093C5.9666 2.65016 6.09731 2.62402 6.22932 2.62402C6.36134 2.62402 6.49204 2.65016 6.6139 2.70093C6.73576 2.7517 6.84636 2.82609 6.93932 2.91982L10.4693 6.45982C10.6556 6.64719 10.7601 6.90064 10.7601 7.16483C10.7601 7.42901 10.6556 7.68246 10.4693 7.86983Z"
        fill="#FF7765"
      />
    </svg>
  )
}
